<template>
    <div class="wrap">
        <div class="nav_ctnt child_wrap">
            <ul @click="closeNav">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'login' }"> Login </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'signup' }"> Signup </router-link>
                </li>
                <li>Documentation</li>
                <li>
                    <router-link :to="{ name: 'FAQ' }"> faqs </router-link>
                </li>
            </ul>
            <div class="rivet_socials icons">
                <div class="git icon">
                    <a href="https://github.com/openrelayxyz/ethercattle-deployment">
                        <img src="@/assets/github.svg" alt="" />
                    </a>
                </div>
                <div class="mdm icon">
                    <a href="https://medium.com/rivet-magazine">
                        <img src="@/assets/medium.svg" alt="" />
                    </a>
                </div>
                <div class="twtr icon">
                    <a href="https://twitter.com/R_I_V_E_T">
                        <img src="@/assets/twitter.svg" alt="" />
                    </a>
                </div>
                <div class="discd icon">
                    <a href="https://discord.gg/9eZUjjPs">
                        <img src="@/assets/discord.svg" alt="" />
                    </a>
                </div>
            </div>
            <footer class="links footer">
                <p>
                    <a href="">Subscribe to newsletter</a>
                    &nbsp;
                    <i class="ri-external-link-line"></i>
                </p>
                <p>
                    <a href="">Contact Support</a>
                    &nbsp;
                    <i class="ri-external-link-line"></i>
                </p>
            </footer>
        </div>
    </div>
</template>

<script setup>
import { onMounted } from "@vue/runtime-core";
import { gsap, Circ } from "gsap";
import { useStore } from "vuex";
const store = useStore();
onMounted(() => {
    if (store.state.toggleMenu == true) {
        gsap.from(".nav_ctnt ul li", {
            opacity: 0.01,
            x: -25,
            ease: Circ.easeOut,
            delay: 0.2,
            stagger: 0.14,
        });

        gsap.from(".rivet_socials div", {
            opacity: 0,
            delay: 0.6,
            stagger: 0.14,
            ease: Circ.easeOut,
            rotateX: -90,
        });

        gsap.from(".links p", {
            opacity: 0,
            delay: 1,
            stagger: 0.14,
            ease: Circ.easeOut,
            x: -20,
        });
    }
});

const closeNav = () => {
    store.state.toggleMenu = !store.state.toggleMenu;
};
</script>

<style scoped lang="scss">
.wrap {
    position: fixed;
    top: 0;
    background: rgb(0, 0, 0);
    left: 0;
    display: flex;
    z-index: 190;
    right: 0;
    bottom: 0;
    .nav_ctnt {
        @include flex_col(3rem 0px);
        justify-content: center;
        ul {
            @include flex_col(2rem 0px);
            cursor: pointer;
            li {
                font-family: "Monument";
                list-style: none;
                color: white;
                font-size: 1.7em;
            }
        }
        .icons {
            @include flex(left, center, 0 1em);
            .icon {
                width: 40px;
                img {
                    width: 100%;
                }
            }
        }
        footer {
            color: $fadegrey;
            font-family: "Dm sans";
            @include flex_col(0.5rem 0px);
            font-size: 1em;
            p {
                @include flex(flex-start, center, none);
            }
        }
    }
}
</style>
