import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createGtm } from "vue-gtm";
import VueGtag from "vue-gtag";
import store from "./store";
import "animate.css";
import "./style/main.scss";
import "remixicon/fonts/remixicon.css";
import "locomotive-scroll/dist/locomotive-scroll.min.css";
import vueCountryRegionSelect from "vue3-country-region-select";

createApp(App)
    .use(store)
    .use(router)
    .use(vueCountryRegionSelect)
    .use(
        createGtm({
            id: "GTM-MP7HWWL",
            vueRouter: router,
            loadScript: true,
        })
    )
    .use(
        VueGtag,
        {
            config: {
                id: "G-C6RD6L66MJ",
            },
        },
        router
    )
    .mount("#app");
