import { createRouter, createWebHistory } from "vue-router";
import setMetatags from "../scripts/utils/router-meta";
const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home.vue"),
        meta: {
            title: "Rivet - Develop, Deploy and Scale Decentralized applications on the Ethereum blockchain",
        },
    },
    {
        path: "/:catchAll(.*)",
        name: "404",
        component: () => import("@/views/404"),
        meta: {
            title: "Oops {-_-}",
        },
    },
    {
        path: "/rescue",
        name: "forgot password",
        component: () => import("@/views/forgot-password.vue"),
        meta: {
            title: "Forgot password? Reset here",
        },
    },
    {
        path: "/privacy-policy",
        name: "privacy policy",
        component: () => import("@/views/privacypolicy.vue"),
        meta: {
            title: "Privacy Policy",
        },
    },
    {
        path: "/terms",
        name: "terms",
        component: () => import("@/views/termsofuse.vue"),
        meta: {
            title: "Terms of Use",
        },
    },
    {
        path: "/FAQ's",
        name: "FAQ",
        component: () => import("@/views/faq.vue"),
        meta: {
            title: "Rivet FAQ's",
        },
    },
    {
        path: "/buidl",
        name: "buidl",
        component: () => import("@/views/buidl.vue"),
        children: [
            {
                path: "login",
                name: "login",
                component: () => import("@/layouts/Auth/login.vue"),
                meta: {
                    title: "Rivet | Login",
                },
            },
            {
                path: "signup",
                name: "signup",
                component: () => import("@/layouts/Auth/signup.vue"),
            },
            {
                path: "signup/activate/",
                name: "activate",
                component: () => import("@/layouts/Auth/activate.vue"),
            },
        ],
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard.vue"),
        children: [
            {
                path: "overview",
                name: "overview",
                component: () => import("@/layouts/dashboard/overview.vue"),
                meta: {
                    title: "Rivet | Overview",
                },
            },
            {
                path: "plan-details",
                name: "plan details",
                component: () => import("@/layouts/dashboard/planDetails.vue"),
                meta: {
                    title: "Rivet | Plan Details",
                },
            },
            {
                path: "account-info",
                name: "account",
                component: () => import("@/layouts/dashboard/account.vue"),
                meta: {
                    title: "Rivet | Account",
                },
            },
            {
                path: "/org-setup",
                name: "setup-org",
                component: () => import("@/layouts/dashboard/setupOrganization.vue"),
                meta: {
                    title: "Create an Organization",
                },
            },
            {
                path: "/invoices",
                name: "invoices",
                component: () => import("@/layouts/dashboard/invoices.vue"),
                meta: {
                    title: "Invoices",
                },
            },
            {
                path: "/buy-credits",
                name: "Buy Credits",
                component: () => import("@/layouts/dashboard/buyCredits.vue"),
                meta: {
                    title: "Buy Credits",
                },
            },

            {
                path: "/update-plan",
                name: "Update plan",
                component: () => import("@/layouts/dashboard/updateplan.vue"),
                meta: {
                    title: "Update Plan | Rivet.cloud",
                },
            },
        ],
    },
    {
        path: "/paymethod",
        name: "Paymethod",
        component: () => import("@/views/addCreditCard.vue"),
        meta: {
            title: "Add Credit Card",
        },
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return {
            behavior: "smooth",
            top: 0,
        };
    },
});

export default router;
setMetatags();
