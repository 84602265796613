import { createStore } from "vuex";
export default createStore({
    state: {
        hasDisplayed: false,
        keys: {},
        keys_item: [],
        errMsg: null,
        loader: null,
        toggleMenu: false,
        authenticated: false,
        token_for_purchase: "DAI",
    },
    mutations: {
        toggleView(state) {
            state.showView = !state.showView;
        },
        toggleLoader(state) {
            state.loader = !state.loader;
        },
    },
    actions: {
        copyToClipBoard(commit, payload) {
            window.navigator.clipboard.writeText(payload);
        },
        toggleView(context) {
            context.commit("toggleView");
        },
    },
    modules: {},
});
